import React from 'react'
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  createStyles,
  WithStyles,
  withStyles,
  Grid,
} from '@material-ui/core'
import { SaveTwoTone } from '@material-ui/icons'
import { ValidatorForm } from 'react-material-ui-form-validator'
import theme from 'theme'

import { TelemetryType } from 'api/alertservice'
import {
  getReadableTelemetryType,
  getTelemetryTypeUnit,
  getSiteProperty,
} from 'components/utils/converter'
import { Site, Asset, BalancedTank } from 'store/Site'
import { TitleAndSubtitle } from 'components/misc/TitleAndSubtitle'
import {
  isShowingAssetInfoExtraFields,
  getFlowFields,
  getTankFields,
  getLiquidFlowFields,
  getGasFlowFields,
} from './AssetForms'
import { ModelState } from 'store/ModelState'
import { connect } from 'react-redux'
import { AppState } from 'store/AppState'
import { getSiteClient } from 'api/client'
import { deepCopyItem } from 'components/utils'
import { Color } from '@material-ui/lab'

import {
  hasSupervisorUserAccessLevel,
  isAdmin,
  isSystemAdmin,
} from 'store/oidc/userManager'
import { MultitenantUserState } from '../../../store/reducers/multitenant'
import { deleteThresholds, Threshold } from 'api/thresholdClient'
import { BalancedTankAssetInfo } from './BalancedTankAssetInfo'
import { TelemetryMessage } from 'store/SiteDetails'
import { ConfirmDialog } from 'components/misc/ConfirmDialog'

const styles = () =>
  createStyles({
    dialogPaperWidthSm: {
      maxWidth: 'unset',
    },
    assetForm: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
  })

interface ComponentState<T> extends ModelState {
  readonly formAsset: Asset
  readonly isEdited: boolean
  readonly formValid: boolean
  readonly siteUpdating: boolean
  readonly editedBTank?: BalancedTank
  readonly bTankConfirmationOpen: boolean
}

interface Props {
  showAssetInfoTelemetryType?: TelemetryType
  loadSite(): void
  asset: Asset
  site?: Site
  siteThresholds: Threshold[]
  latestTelemetry: TelemetryMessage[]
  closeAssetInfoDialog(): void
  showSiteUpdateNotification(updateResult?: Color): void
}
interface PropsFromState {
  readonly user: MultitenantUserState
  readonly accessToken?: string
  readonly tenantId: number
}

export interface GasFlowValues {
  VolumeFlowRate: string
  TodayVolumeFlowed: string
  YesterdayVolumeFlowed: string
  StaticPressure: string
  DiffPressure: string
  Temperature: string
  BatteryVolts: string
  AccumulatedVolume: string
  PipeDiameter: string
  OrificeDiameter: string
}

type AllProps = Props & WithStyles<typeof styles> & PropsFromState

class AssetInfoDialog<TSubState = {}> extends React.Component<
  AllProps,
  ComponentState<TSubState>
> {
  constructor(props: AllProps) {
    super(props)
    this.state = {
      formAsset: this.props.asset,
      isEdited: false,
      formValid: false,
      siteUpdating: false,
      bTankConfirmationOpen: false,
      editedBTank:
        this.props.showAssetInfoTelemetryType === TelemetryType.BalancedTank
          ? (this.props.asset as BalancedTank)
          : undefined,
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleBTankChange = this.handleBTankChange.bind(this)
  }

  isSystemAdmin = isSystemAdmin(this.props.user.selectedTenant?.roles)
  isAdmin = isAdmin(this.props.user.selectedTenant?.roles)
  validRole = hasSupervisorUserAccessLevel(
    this.props.user.selectedTenant?.roles
  )

  telemetryType = this.props.showAssetInfoTelemetryType!
  siteAPI = getSiteClient(
    this.props.accessToken!,
    this.props.tenantId.toString()
  )

  showExtraFields = isShowingAssetInfoExtraFields(
    this.props.asset,
    this.telemetryType
  )

  formRef = React.createRef<ValidatorForm>()
  isBTank = this.props.showAssetInfoTelemetryType === TelemetryType.BalancedTank

  private getAssetFormFields(telemetryType: TelemetryType) {
    if (
      telemetryType === TelemetryType.TankLevel ||
      telemetryType === TelemetryType.BalancedTank
    )
      return getTankFields(
        this.state.formAsset,
        this.handleInputChange,
        this.validRole
      )
    if (telemetryType === TelemetryType.Flow)
      return getFlowFields(
        this.state.formAsset,
        this.handleInputChange,
        this.validRole
      )
    if (telemetryType === TelemetryType.LiquidFlow)
      return getLiquidFlowFields(
        this.state.formAsset,
        this.handleInputChange,
        this.validRole
      )
    if (telemetryType === TelemetryType.GasFlow)
      return getGasFlowFields(
        this.state.formAsset,
        this.handleInputChange.bind(this),
        this.validRole,
        this.getGasFlowLatestTelemtryValues(this.props.latestTelemetry) || {}
      )

    return null
  }

  private handleInputChange(value, id) {
    if (this.telemetryType !== TelemetryType.GasFlow) {
      const regexp = /(?=[^\0])(?=^([0-9]){1,7}(\.[0-9]{1,7}){0,1}$)/
      if (!regexp.test(value) || isNaN(parseFloat(value))) return
    }

    const formAssetToModify =
      this.telemetryType === TelemetryType.GasFlow
        ? deepCopyItem({ ...this.state.formAsset, totalFlowType: value })
        : deepCopyItem(this.state.formAsset)

    if (this.telemetryType !== TelemetryType.GasFlow) {
      formAssetToModify[id] =
        value.length <= 0 || isNaN(parseFloat(value)) ? '' : parseFloat(value)
    }

    this.setState(
      {
        formAsset: formAssetToModify,
        isEdited:
          JSON.stringify(formAssetToModify) !==
          JSON.stringify(this.props.asset),
      },
      async () => {
        this.setState({
          formValid: await this.isFormValid()!,
        })
      }
    )
  }

  handleBTankChange(
    tankId: string,
    property: string,
    value: any,
    isReciver: boolean,
    formValid: boolean
  ) {
    const newBTank: BalancedTank = deepCopyItem(this.state.editedBTank)
    if (isReciver) {
      newBTank[property] = parseFloat(value) // for gravity property, review when new properties are requested
    } else {
      newBTank.tanks.find((tank) => tank.id === tankId)![property] = value
    }
    this.setState({
      editedBTank: newBTank,
    })
    this.checkBalancedTanksEquality(
      this.props.asset as BalancedTank,
      newBTank,
      formValid
    )
  }

  checkBalancedTanksEquality(bTank, editedBTank, formValid?: boolean) {
    this.setState({
      isEdited: JSON.stringify(bTank) !== JSON.stringify(editedBTank),
      formValid: formValid!,
    })
  }

  private async updateSite(site) {
    const locationData = {
      lon: site.longitude ?? 0,
      lat: site.latitude ?? 0,
      location: {
        state: site.state ?? '',
        county: site.county ?? '',
        township: site.township ?? '',
        region: site.region ?? '',
        field: site.field ?? '',
      },
    }

    const dataWithLocation = { ...site, ...locationData }

    try {
      this.setState({
        siteUpdating: true,
      })
      await this.siteAPI.updateSite(dataWithLocation)
      await this.siteAPI.updateCache()
      this.props.loadSite()
      this.props.showSiteUpdateNotification('success')
      this.props.closeAssetInfoDialog()
    } catch (error) {
      this.setState({
        siteUpdating: false,
      })
      this.props.showSiteUpdateNotification('error')
      console.error(error)
    }
  }

  renderBTankConfirmation() {
    return (
      <ConfirmDialog
        titleOkButton="Agree"
        onAction={(ok) => {
          if (!ok) {
            this.closeThresholdsDialog()
          }

          if (ok) {
            this.handleConfirmation()
          }
        }}
      >
        This tank has thresholds set up, if it is desactivated the thresholds
        will be eliminated and must be re-created
      </ConfirmDialog>
    )
  }

  closeThresholdsDialog() {
    this.setState({
      bTankConfirmationOpen: false,
    })
  }

  handleSubmit(event) {
    event.preventDefault()

    if (this.isBTank) {
      const tankHasThreshold = this.props.siteThresholds.some(
        (threshold) => threshold.AssetId === this.state.editedBTank?.id
      )
      let activeTanksDeactivated = false

      const previousActiveTanks = (
        this.props.asset as BalancedTank
      ).tanks.filter((tank) => tank.isInactive === false)
      for (const tank of previousActiveTanks) {
        const editedTank = this.state.editedBTank?.tanks.find(
          (t) => t.id === tank.id
        )
        if (tank.isInactive !== editedTank?.isInactive) {
          activeTanksDeactivated = true
          break
        }
      }

      if (tankHasThreshold && activeTanksDeactivated) {
        this.setState({
          bTankConfirmationOpen: true,
        })
        return
      }
      this.beginUpdate()
      return
    }

    this.beginUpdate()
  }

  handleConfirmation() {
    const thresholdId = this.props.siteThresholds.find(
      (th) => th.AssetId === this.props.asset.id
    )?.Id

    this.closeThresholdsDialog()
    this.setState({
      siteUpdating: true,
    })
    deleteThresholds(
      this.props.accessToken!,
      this.props.tenantId.toString(),
      thresholdId!
    )
    this.beginUpdate()
  }

  private beginUpdate() {
    const assetProperty = getSiteProperty(this.props.showAssetInfoTelemetryType)

    const siteToUpdate = deepCopyItem(this.props.site!)

    const index = siteToUpdate[assetProperty].findIndex(
      (asset: Asset) => asset.id === this.state.formAsset.id
    )

    siteToUpdate[assetProperty][index] = this.state.formAsset

    siteToUpdate[assetProperty][index] = this.isBTank
      ? this.state.editedBTank
      : this.state.formAsset
    if (siteToUpdate.startDate !== undefined) {
      siteToUpdate.startDate = new Date(siteToUpdate.startDate).toISOString()
    }

    this.updateSite(siteToUpdate)
  }

  private isFormValid() {
    return this.formRef.current?.isFormValid(false)
  }

  private renderFields() {
    return (
      <ValidatorForm
        ref={this.formRef}
        onSubmit={this.handleSubmit}
        instantValidate={true}
      >
        <Grid
          container
          alignItems="center"
          justify="flex-start"
          direction="row"
          spacing={2}
        >
          {this.getAssetFormFields(this.telemetryType)}
        </Grid>
      </ValidatorForm>
    )
  }

  private getGasFlowLatestTelemtryValues(
    data: TelemetryMessage[]
  ): GasFlowValues {
    const { value } =
      data.find((item) => item.TelemetryType === TelemetryType.GasFlow)
        ?.Payload || {}
    return value
  }

  public render() {
    const assetLatestTelemetry = this.props.latestTelemetry.find(
      (telemetry) => telemetry.AssetId === this.props.asset.id
    )

    const assetSelected = this.props.asset

    return (
      <>
        <Dialog
          disableEscapeKeyDown={true}
          open={true}
          classes={{
            paperWidthSm: this.props.classes.dialogPaperWidthSm,
          }}
        >
          <DialogContent dividers={true}>
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  fontSize: 12,
                }}
              >
                <TitleAndSubtitle
                  title={`${getReadableTelemetryType(this.telemetryType)} ID: ${
                    assetSelected.id
                  }`}
                  subtitle={
                    assetSelected.telemetryTypeFormula === ' '
                      ? ' '
                      : this.isSystemAdmin
                      ? `${getReadableTelemetryType(this.telemetryType)} 
                  Formula (${getTelemetryTypeUnit(this.telemetryType)}): ${
                          assetSelected.telemetryTypeFormula
                        }`
                      : undefined
                  }
                />
              </div>
              {this.props.showAssetInfoTelemetryType ===
              TelemetryType.BalancedTank ? (
                <div
                  style={{
                    width: '100%',
                    margin: theme.spacing(1, 0.5, 0, 1),
                  }}
                >
                  <BalancedTankAssetInfo
                    bTank={this.state.editedBTank!}
                    assetLatestTelemetry={assetLatestTelemetry!}
                    handleBTankChange={this.handleBTankChange}
                    isAdmin={this.isAdmin}
                  />
                </div>
              ) : (
                this.showExtraFields && (
                  <div
                    style={{
                      width: '100%',
                      margin: theme.spacing(1, 0.5, 0, 1),
                    }}
                  >
                    {this.renderFields()}
                  </div>
                )
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.closeAssetInfoDialog} color="primary">
              Close
            </Button>
            {this.showExtraFields && this.validRole && (
              <Button
                disabled={
                  !this.state.isEdited ||
                  !this.state.formValid ||
                  this.state.siteUpdating
                }
                style={{ width: '120px' }}
                variant="contained"
                color="primary"
                startIcon={<SaveTwoTone />}
                form="assetInfoForm"
                onClick={this.handleSubmit}
              >
                {this.state.siteUpdating ? 'Saving' : 'Save'}
              </Button>
            )}
          </DialogActions>
        </Dialog>
        {this.state.bTankConfirmationOpen && this.renderBTankConfirmation()}
      </>
    )
  }
}

const mapPropsFromState = ({
  oidc: { user },
  multitenantUser,
}: AppState): PropsFromState => ({
  accessToken: multitenantUser.accessToken,
  tenantId: multitenantUser.tenants?.find((t) => t.selected)?.id || 0,
  user: multitenantUser,
})

export default connect(mapPropsFromState)(withStyles(styles)(AssetInfoDialog))
