import React from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router'
import {
  actionCreators as appConfigActionCreators,
  AppConfigState,
} from './store/AppConfig'
import { actionCreators as sitesActionCreators } from './store/Site'
import {
  actionCreators as groupActionCreators,
  GroupsState,
} from './store/Group'
import { actionCreators as alertActionCreators } from './store/Alert'
import { actionCreators as userSettingsActionCreators } from './store/UserSettings'
import { actionCreators as userAppSettingsActionCreators } from './store/UserAppSettings'
import { actionCreators as commonActionCreators } from './store/reducers/common'
import UserSettings from './components/userSettings/index'
import Layout from './components/Layout'
import Home from './components/Home'
import IntermittentPanel from './components/Intermittent'
import Configuration from './components/Configuration'
import { AppState } from './store/AppState'
import MyAlerts from './components/MyAlerts'
import { LinearProgress } from '@material-ui/core'
import SiteRouter from 'components/routers/SiteRouter'
import AllSites from 'components/AllSites'
import { InitializeTagManager } from 'TagManager'
import AlertComponent from './components/common/GenericAlert'
import EmailAcknowledge from './components/EmailAcknowledge'
import PumpEmailAcknowledge from './components/PumpEmailAcknowledge'
import { UserSettingsResponseDto } from 'api/identityprovider'
import { getUserTimeZoneObj } from './utils/localStorage'
import {
  AuthenticatedTemplate,
  MsalContext,
  UnauthenticatedTemplate,
} from '@azure/msal-react'
import SignIn from 'components/SignIn'
import MyPumpAlerts from 'components/MyPumpAlerts'

export const homeRoute = '/'
export const homeFailedSigninRoute = '/failed-signin'
export const IntermittentPanelRoute = '/intermittent-panel'
export const allSitesRoute = '/allsites'
export const sitesRoute = '/sites'
export const siteRoute = '/site'
export const idRoute = '/:id'
export const devicesRoute = '/devices'
export const deviceRoute = '/device/:deviceId'
export const telemetryTypeRoute = '/telemetryType/:telemetryType'
export const plungerLiftRoute = '/plungerLift'
export const pumpControllersRoute = '/pumpControllers'
export const alertsRoute = '/myalerts'
export const pumpAlertsRoute = '/mypumpalerts'
export const configurationRoute = '/config'
export const oidcCallbackRoute = '/callback'
export const emailAcknowledgeRoute = '/email-acknowledge'
export const pumpEmailAcknowledgeRoute = '/pump-email-acknowledge'
export const adminRoute =
  process.env.NODE_ENV === 'production' ? '/mgmt' : 'https://localhost:5101'
export const userSettingsRoute = '/settings'

const timeZoneSettingSearch = (userSetting) => userSetting.key === 'TimeZone'

interface PropsFromState {
  readonly appConfigState: AppConfigState
  readonly accessToken?: string
  readonly tenantId: number
  readonly userId?: string
  readonly groups: GroupsState
  readonly userAppSettings: any
  readonly userAppSettingsError?: any
}

type PropsFromDispatch = typeof appConfigActionCreators &
  typeof sitesActionCreators &
  typeof groupActionCreators &
  typeof alertActionCreators &
  typeof userSettingsActionCreators &
  typeof userAppSettingsActionCreators &
  typeof commonActionCreators

type AllProps = PropsFromState & PropsFromDispatch

declare global {
  interface Window {
    google_tag_manager: any
  }
}

class App extends React.Component<AllProps> {
  static contextType = MsalContext
  public componentDidMount() {
    this.props.loadAppConfig()
  }

  public componentDidUpdate(prevProps: PropsFromState) {
    const {
      accessToken,
      tenantId,
      userId,
      groups: { groups, loading },
      appConfigState: { gtmContainerId },
      userAppSettings,
      userAppSettingsError,
    } = this.props

    if (
      (prevProps.tenantId && prevProps.tenantId !== tenantId && accessToken) ||
      (!prevProps.accessToken && accessToken)
    ) {
      this.props.loadUserSettings()
      this.props.requestSites(accessToken, tenantId.toString())
      this.props.requestAvailableGroups(accessToken, tenantId.toString())
    }

    if (
      (prevProps.tenantId &&
        prevProps.tenantId !== tenantId &&
        accessToken &&
        userId) ||
      (accessToken && tenantId && !prevProps.userId && userId)
    ) {
      this.props.requestAllActiveAlertsCount(accessToken, userId, tenantId)
      this.initUserSettings(accessToken, userId, tenantId)
    }

    if (
      (prevProps.tenantId && prevProps.tenantId !== tenantId && accessToken) ||
      (accessToken && tenantId && prevProps.groups.loading && !loading)
    ) {
      this.props.stopAlertsStream()
      this.props.requestAlertStream(
        accessToken,
        tenantId.toString(),
        groups.map((g) => g.id)
      )
    }

    if (
      (prevProps.tenantId &&
        prevProps.tenantId !== tenantId &&
        gtmContainerId) ||
      (gtmContainerId && !window.google_tag_manager)
    ) {
      InitializeTagManager(gtmContainerId)
    }

    if (
      (prevProps.tenantId && prevProps.tenantId !== tenantId) ||
      userAppSettingsError ||
      prevProps.userAppSettings
    ) {
      const prevTimezoneIndex = prevProps.userAppSettings.findIndex(
        timeZoneSettingSearch
      )
      const currentTimezoneIndex = userAppSettings.findIndex(
        timeZoneSettingSearch
      )

      if (userAppSettingsError) {
        this.showAlertMessage(
          `Something wrong happened when trying to change the TimeZone`,
          true,
          'error'
        )
        return
      }

      if (
        prevProps.userAppSettings[prevTimezoneIndex].value !==
        userAppSettings[currentTimezoneIndex].value
      ) {
        this.showAlertMessage(
          `Your TimeZone has changed to ${this.getLocalTimeZone()}`,
          true,
          'warning'
        )
      }
    }
  }

  public componentWillUnmount() {
    this.props.stopAlertsStream()
  }

  private getLocalTimeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  }

  public async initUserSettings(accessToken, userId, tenantId) {
    await this.props.loadUserAppSettings(accessToken, userId, tenantId)
    this.verifyTimeZone(accessToken, userId, tenantId)
  }

  public verifyTimeZone(accessToken: string, userId: string, tenantId: string) {
    const localTimeZone = this.getLocalTimeZone()
    const userSettingsTimeZone = this.getLocalStorageTimeZone()
    if (!userSettingsTimeZone || userSettingsTimeZone !== localTimeZone) {
      const userSetting: UserSettingsResponseDto =
        UserSettingsResponseDto.fromJS({
          key: 'TimeZone',
          value: localTimeZone,
        })

      this.props.updateUserAppSetting(
        accessToken,
        userId,
        userSetting,
        tenantId
      )
    }
  }

  public getLocalStorageTimeZone() {
    const timeZoneSettingsObj = getUserTimeZoneObj()
    return timeZoneSettingsObj ? timeZoneSettingsObj.value : null
  }

  private readonly showAlertMessage = (message, status, severity) => {
    this.props.setHandleAlert({
      message,
      status,
      severity,
    })
  }

  public render() {
    const {
      appConfigState: { loading, error },
    } = this.props

    if (loading) {
      return <LinearProgress />
    }

    if (error) {
      return this.renderAppConfigLoadError()
    }

    return this.renderLayout()
  }

  private renderAppConfigLoadError() {
    return this.props.appConfigState.error
  }

  private renderLayout() {
    return (
      <>
        <Route
          path={emailAcknowledgeRoute}
          component={EmailAcknowledge}
          exact
        />
        <Route
          path={pumpEmailAcknowledgeRoute}
          component={PumpEmailAcknowledge}
          exact
        />
        <Switch>
          <AuthenticatedTemplate>
            <Layout>
              <Route path={homeRoute} component={Home} exact={true} />
              <Route
                path={IntermittentPanelRoute}
                component={IntermittentPanel}
              />
              <Route path={sitesRoute} component={AllSites} />
              <Route path={alertsRoute} component={MyAlerts} />
              <Route path={pumpAlertsRoute} component={MyPumpAlerts} />
              <Route path={configurationRoute} component={Configuration} />
              <Route path={siteRoute} component={SiteRouter} />
              <Route path={userSettingsRoute} component={UserSettings} />
              <AlertComponent />
            </Layout>
          </AuthenticatedTemplate>
        </Switch>
        <UnauthenticatedTemplate>
          <Route path="/" exact>
            <SignIn />
          </Route>
          <Route path={homeFailedSigninRoute}>
            <SignIn />
          </Route>
        </UnauthenticatedTemplate>
      </>
    )
  }
}

const mapStateToProps = ({
  appConfig,
  oidc: { user },
  groups,
  userAppSettings,
  multitenantUser,
}: AppState) => ({
  groups,
  appConfigState: appConfig,
  accessToken: multitenantUser.accessToken,
  userId: multitenantUser.id,
  tenantId: multitenantUser.tenants?.find((t) => t.selected)?.id || 0,
  userAppSettings: userAppSettings.userAppSettings,
  userAppSettingsError: userAppSettings?.error,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators(appConfigActionCreators, dispatch),
  ...bindActionCreators(sitesActionCreators, dispatch),
  ...bindActionCreators(groupActionCreators, dispatch),
  ...bindActionCreators(alertActionCreators, dispatch),
  ...bindActionCreators(userSettingsActionCreators, dispatch),
  ...bindActionCreators(userAppSettingsActionCreators, dispatch),
  ...bindActionCreators(commonActionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
