import * as React from 'react'
import { TelemetryType } from '../../api/apiservice'

import BaseSensor from './BaseSensor'
export default class CasingPressure extends BaseSensor {
  constructor(props) {
    super(props, TelemetryType.CasingPressure, 'Casing Pressure')
  }

  protected formatValue = (value?: any, timeStamp?: Date): React.ReactNode => {
    if (typeof value !== 'number') {
      return 'N/A'
    }
    return <React.Fragment>{value}</React.Fragment>
  }

  protected getDisplayReading(readingValue?: number): number | undefined {
    return readingValue
  }
}
