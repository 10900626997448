import { ModelState } from './ModelState'
import { Reducer, Dispatch, AnyAction } from 'redux'
import { AppState } from './AppState'
import { parseError } from './utils'
import { getAppConfiguration } from '../api/client'
import { ConfigurationViewModel } from '../api/webportal'

const requestAppConfigType = 'REQUEST_APP_CONFIG'
const receiveAppConfigType = 'RECEIVE_APP_CONFIG'
const receiveAppConfigErrorType = 'RECEIVE_APP_CONFIG_ERROR'

export interface AppConfigState extends ModelState {
  readonly buildVersion?: string
  readonly mapApiKey?: string
  readonly gtmContainerId?: string
  readonly launchDarklyClientsideId?: string
  readonly clientName?: string
}

export const defaultState: AppConfigState = {}

export const actionCreators = {
  loadAppConfig: () => async (dispatch: Dispatch, _: () => AppState) => {
    dispatch({ type: requestAppConfigType })

    try {
      const result = await getAppConfiguration()

      if (result instanceof Error) {
        console.error(result)
        dispatch({
          error: result,
          type: receiveAppConfigErrorType,
        })
        return
      }

      dispatch({
        appConfig: result,
        type: receiveAppConfigType,
      })
    } catch (error) {
      console.error(error)
      dispatch({
        error,
        type: receiveAppConfigErrorType,
      })
    }
  },
}

export const reducer: Reducer<AppConfigState> = (
  state = defaultState,
  action: AnyAction
) => {
  switch (action.type) {
    case requestAppConfigType:
      return {
        ...state,
        error: undefined,
        loading: true,
      }

    case receiveAppConfigType:
      const config: ConfigurationViewModel = action.appConfig

      return {
        ...state,
        buildVersion: config.buildVersion,
        mapApiKey: config.googleMapsApiKey,
        gtmContainerId: config.gtmContainerId,
        launchDarklyClientsideId: config.launchDarklyClientsideId,
        clientName: config.clientName,
        loading: false,
        error: undefined,
      }

    case receiveAppConfigErrorType:
      return {
        ...state,
        error: parseError(action.error),
        loading: false,
      }

    default:
      return state
  }
}
